/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'store/core/@reduxjs/toolkit';
import { defaultFilter } from './constant';
import {
  DetailPatientType,
  DetailProfileType,
  FilterProps,
  ProfileType,
  PatientWrapper,
} from './types';

export interface MultiProfileState {
  loading: boolean;
  list: ProfileType[];
  listFilter: FilterProps;
  detail: DetailProfileType | null;
  detailPatient: DetailPatientType | null;
  listFind: PatientWrapper | null;
  bhyt: any;
}

export const initialState: MultiProfileState = {
  loading: false,
  list: [],
  listFilter: defaultFilter,
  detail: null,
  detailPatient: null,
  listFind: null,
  bhyt: null,
};

const MultiProfileSlice = createSlice({
  name: 'multiProfile',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },

    list(state, action: PayloadAction<FilterProps>) {
      state.loading = true;
      state.listFilter.userId = action.payload.userId;
      state.listFilter.hospitalCode = action.payload.hospitalCode;
    },
    listSuccess(state, action: PayloadAction<ProfileType[]>) {
      state.loading = false;
      state.list = action.payload;
    },
    listFailure(state, action) {
      state.loading = false;
      state.list = [];
    },

    detail(state, action: PayloadAction<{ id: string }>) {
      state.loading = true;
    },
    detailSuccess(state, action: PayloadAction<DetailProfileType | null>) {
      state.loading = false;
      state.detail = action.payload;
    },
    detailFailure(state, action) {
      state.loading = false;
    },

    add(state, action) {
      state.loading = true;
    },
    addSuccess(state, action) {
      state.loading = false;
    },
    addFailure(state, action) {
      state.loading = false;
    },

    update(state, action) {
      state.loading = true;
    },
    updateSuccess(state, action) {
      state.loading = false;
    },
    updateFailure(state, action) {
      state.loading = false;
    },

    remove(state, action) {
      state.loading = true;
    },
    removeSuccess(state, action) {
      state.loading = false;
    },
    removeFailure(state, action) {
      state.loading = false;
    },

    setDefault(state, action: PayloadAction<{ id: string }>) {
      state.loading = true;
    },
    setDefaultSuccess(state, action) {
      state.loading = false;
    },
    setDefaultFailure(state, action) {
      state.loading = false;
    },

    findPatient(
      state,
      action: PayloadAction<{
        hospitalCode: 'BVNB' | 'BVBN';
        searchKey: string;
      }>,
    ) {
      state.loading = true;
    },
    findPatientSuccess(state, action: PayloadAction<DetailPatientType>) {
      state.loading = false;
      state.detailPatient = action.payload;
    },
    findPatientFailure(state, action) {
      state.loading = false;
      state.detailPatient = null;
    },
    find(state, action) {
      state.loading = true;
    },
    findSuccess(state, action) {
      state.loading = false;
      state.listFind = action.payload;
    },
    findFailure(state, action) {
      state.loading = false;
      state.listFind = null;
    },
    clearListFind(state) {
      state.listFind = null;
    },
    getBhyt(state, action) {
      state.loading = true;
    },
    getBhytSuccess(state, action) {
      state.loading = false;
      state.bhyt = action.payload;
    },
    getBhytFailure(state, action) {
      state.loading = false;
      state.bhyt = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = MultiProfileSlice;
