/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  dataSearch,
  informationRegisteredExamination,
  infoUserPatientCode,
  userLinkProfile,
} from 'app/containers/SearchExaminationInformation/types/TypeExaminationInformation';
import { createSlice } from 'store/core/@reduxjs/toolkit';
import _ from 'lodash';
import {
  DepartmentType,
  SpecialType,
} from 'app/containers/InfoPatient/Type/TypeFormPatient';
export interface SharedState {
  loading: boolean;
  error: any;
  success: boolean;
  hospitalCode: string;
  specialists: SpecialType[];
  departments: DepartmentType[];
  listNumberOnline: any[];
  userInforPatientCode: infoUserPatientCode | any;
  userInforPatientCodeHopital: infoUserPatientCode | any;
  allPatientofUser: Array<userLinkProfile>;
  infoRegisterExaminationFilter: Array<informationRegisteredExamination> | any;
  informationUserBookingCode: dataSearch | any;
  LoadPagepatientReCords: string;
  isCheckLinkHomePage: boolean;
  isLinkReGisterPage: boolean;
  isLinkLoginPage: boolean;
  valueSearchHistory: string;
  userInforPatientCodeSearch: any;
  seachValuesCurrent: string;
  ListReExamination: any;
}

export const initialState: SharedState = {
  loading: false,
  error: null,
  success: false,
  hospitalCode: localStorage.getItem('__hospitalCode') || '',
  specialists: [],
  departments: [],
  listNumberOnline: [],
  userInforPatientCode: {},
  userInforPatientCodeHopital: JSON.parse(
    localStorage.getItem('__userPatientCodeHopital') || '{}',
  ),
  userInforPatientCodeSearch: JSON.parse(
    localStorage.getItem('__userCheckPatients') || '{}',
  ),
  allPatientofUser: [],
  infoRegisterExaminationFilter: [],
  informationUserBookingCode: {},
  LoadPagepatientReCords: 'CheckPatient',
  isCheckLinkHomePage: false,
  isLinkReGisterPage: false,
  isLinkLoginPage: false,
  valueSearchHistory: '',
  seachValuesCurrent: '',
  ListReExamination: null,
};

const SharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    clear(state) {
      return initialState;
    },
    changeHospitalCode(state, action) {
      state.hospitalCode = action.payload || 'BVNB';
      // localStorage.setItem('__hospitalCode', state.hospitalCode || '');
    },

    fetchSpecialists(state, action) {
      // state.loading = true;
      state.specialists = [];
    },
    fetchSpecialistsExamination(state) {
      state.loading = false;
      state.specialists = [];
    },
    fetchSpecialistsSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.specialists = action.payload;
    },
    fetchSpecialistsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

    fetchDepartments(state, action) {
      state.loading = true;
      state.departments = [];
    },
    fetchDepartmentsSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.departments = action.payload;
    },
    fetchDepartmentsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

    fetchTimeSlot(state, action) {
      state.loading = true;
      state.listNumberOnline = [];
    },
    fetchTimeSlotSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.listNumberOnline = action.payload?.list_number_online || [];
    },
    fetchTimeSlotFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getPatientCode(state, action) {
      state.loading = true;
    },
    getPatientCodeSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userInforPatientCodeHopital = action.payload;
      localStorage.setItem(
        '__userPatientCodeHopital',
        JSON.stringify(state.userInforPatientCodeHopital),
      );
    },
    getPatientCodeFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getPatientCodes(state, action) {
      state.loading = true;
    },
    getPatientCodesSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userInforPatientCodeSearch = action.payload;
      localStorage.setItem(
        '__userCheckPatients',
        JSON.stringify(state.userInforPatientCodeSearch),
      );
    },
    getPatientCodesFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getInfoUserFromPatientCode(state, action) {
      if (action.payload?.address?.province_code === undefined) {
        state.userInforPatientCode = state.userInforPatientCodeSearch;
      }
    },
    postPatientMap(state, action) {
      state.loading = true;
    },
    postPatientMapSuccess(state) {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    postPatientMapFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    postPatientUnMap(state, action) {
      state.loading = true;
    },
    postPatientUnMapSuccess(state) {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    postPatientUnMapFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getAllUserPatientCode(state) {
      state.loading = true;
    },
    getAllUserPatientCodeSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.allPatientofUser = action.payload;
    },
    getAllUserPatientCodeFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getRegisterInfoExamination(state) {
      state.loading = true;
    },
    getRegisterInfoExaminationSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.infoRegisterExaminationFilter = action.payload;
    },
    getRegisterInfoExaminationFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    postCancelExamination(state, action) {
      state.loading = true;
    },
    postCancelExaminations(state, action) {
      state.loading = true;
    },
    postCancelExaminationSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    postCancelExaminationFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    postInfoExaminationBookingCode(state, action) {
      state.loading = false;
    },
    postInfoExaminationBookingCodeSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.informationUserBookingCode = action.payload;
    },
    postInfoExaminationBookingCodeFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

    loadPagePatientRecords(state, action) {
      state.LoadPagepatientReCords = action.payload;
    },

    checkLinkHomePage(state, action) {
      state.isCheckLinkHomePage = action.payload;
    },

    LinkRegisterPage(state, action) {
      state.isLinkReGisterPage = action.payload;
    },

    LinkLoginPage(state, action) {
      state.isLinkLoginPage = action.payload;
    },
    resetUserInforPatientCodeHopital(state) {
      state.userInforPatientCodeHopital = {};
      state.userInforPatientCode = {};
      state.allPatientofUser = [];
    },
    searchValueHistory(state, action) {
      state.valueSearchHistory = action.payload;
    },
    clearSearchInformationUser(state) {
      state.userInforPatientCodeHopital = [];
      localStorage.removeItem('__userPatientCodeHopital');
    },
    addSeachValueCurrent(state, action) {
      state.seachValuesCurrent = action.payload;
    },
    getListReExamination(state, action) {
      state.loading = true;
    },
    getListReExaminationSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.ListReExamination = action.payload;
    },
    getListReExaminationFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
      state.ListReExamination = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = SharedSlice;
