export enum nameHistoryRegisterExamination {
  HISTORY_REGISTER_EXAMINATION = 'Lịch sử đăng ký khám',
  MEDICAL_REGISTER_INFORMATION = 'Thông tin đăng ký khám',
  SPECIALIST = 'Chuyên khoa:',
  CLINIC = 'Phòng khám:',
  TYPE_CLINIC = 'Loại hình Khám:',
  DAY_EXAMINATION = 'Ngày khám:',
  ESTIMATED_EXAMINATION_TIME = 'Giờ khám dự kiến:',
  BOOKING_CODE = 'Mã hẹn khám',
  HOSPITAL_CHORAY = 'Bệnh viện Đa Khoa Tỉnh Ninh Bình',
  ADDRESS_HOSPITAL_CHORAY = '201B Nguyễn Chí Thanh, Phường 12, Quận 5, TP. Hồ Chí Minh',
  INTERNAL_MEDICINE_DEPARTMENT = 'khoa nội',
  CODE_PATIENT = 'Mã BN:',
  NOT_REGISTER = 'Không tìm thấy thông tin',
  INFORMATION_PATIENT = 'Thông tin bệnh nhân',
  FULLLNAME_PATIENT = ' Họ tên bệnh nhân:',
  BIRTH_DAY = 'Năm sinh:',
  GENDER = 'Giới tính:',
  PHONE_NUMBER = 'Số điện thoại:',
  BTN_BEFORCE = 'Trước',
  BTN_AFTER = 'Sau',
  PAST = 'Đã qua',
  CANCELLED = 'Đã Hủy',
  NEW_REGISTRATION = 'Đăng ký mới',
  BTN_DOWLOAD_PROMISSORY_NOTE = 'Tải phiếu về máy',
  BTN_CANCEL = 'Hủy lịch',
  STATUS = 'Trạng thái:',
  HOSPITAL = 'Bệnh viện:',
  ALL = 'Tất cả',
}

export enum notifyMessages {
  CANCEL_REGISTER_EXAMINATION_SUCCESS = 'Hủy thông tin đăng ký khám thành công',
  CANCEL_REGISTER_EXAMINATION_FAILED = 'Hủy thông tin đăng ký khám thất bại',
}

export const defaultValueHistory = {
  STATUS_SUCCESS: true,
  STATUS_FAILED: false,
  VALUE_SUCCESS: 1,
  VALUE_FAILED: 0,
  STR_NULL: '',
  NULL: null,
  STR_BORDER: '1px solid #D61B22',
  ITEM_PERPAGE: 3,
  ACTIVE_PAGE: 0,
  PAGE_SIZE: 0,
  INITIAL_PAGE: 0,
  STATUS_FILTER: 3,
  HOSPITAL_ALL: 'All',
};
export const statusRegister = {
  newRegistration: 0,
  yesterdayRegistration: 1,
  cancel: 2,
};
export interface typeRegisterDetail {
  apply_date: string | null | undefined;
  birthday: Date | string | null | undefined;
  booking_code: string | null | undefined;
  create_by_name: string | null | undefined;
  created_by: string | null | undefined;
  created_date: Date | string | null | undefined;
  department_code: string | null | undefined;
  department_id: string | null | undefined;
  department_name: string | null | undefined;
  district_id: string | null | undefined;
  district_name: string | null | undefined;
  doctor_id: string | null | undefined;
  doctor_name: string | null | undefined;
  end_expected: Date | string | null | undefined;
  first_name: string | null | undefined;
  gender: number | null | undefined;
  hospital_code: string | null | undefined;
  hospital_name: string | null | undefined;
  id: string | null | undefined;
  identity_card_number: string | null | undefined;
  is_active: boolean | null | undefined;
  last_name: string | null | undefined;
  list_number_online_detail_id: string | number | null | undefined;
  list_number_online_id: string | null | undefined;
  local_specialist_code: string | null | undefined;
  local_specialist_id: string | null | undefined;
  local_specialist_name: string | null | undefined;
  number: number | null | undefined;
  patient_code: string | null | undefined;
  payment_status: number | null | undefined;
  payment_status_name: null;
  payment_type: string | null | undefined;
  payment_type_name: string | null | undefined;
  phone: string | null | undefined;
  price: number | null | undefined;
  province_id: string | null | undefined;
  province_name: string | null | undefined;
  registered_date: Date | string | null | undefined;
  room_description: string | null | undefined;
  service_type: string | null | undefined;
  start_expected: string | null | undefined;
  status: number | null | undefined;
  street: string | null | undefined;
  sync: boolean | null | undefined;
  url_metting: string | null | undefined;
  user_app_id: string | null | undefined;
  ward_id: string | null | undefined;
  ward_name: string | null | undefined;
}
