import {
  ReExamination,
  PatientType,
} from './../app/containers/InfoPatient/Type/TypeFormPatient';

export const DEFAULT_FORMAT_DATE = 'dd/MM/yyyy';
export const MOMENT_RENDER = 'DD-MM-YYYY';
export const MOMENT_CALL_API = 'yyyy-MM-DD';
export const MOMENT_HOUR_RENDER = 'HH:mm';
export const MOMENT_HOUR_CALL_API = 'HH:mm:ss';
export const DEFAULT_FORMAT_OUT_TIME = 'DD/MM/YYYY';
export const DEFAULT_ID = '00000000-0000-0000-0000-000000000000';
export const MESSAGE_CONST = {
  NOT_ACCOUNT: 'Bạn chưa có tài khoản VIMES. Bấm tiếp tục để tiến hành đăng ký',
};
export const HOSPITAL_TYPE = [
  {
    code: '37101',
    name: 'Bệnh Viện Đa Khoa Tỉnh Ninh Bình',
    address: '80 Tuệ Tĩnh, Nam Thành, Ninh Bình',
    img: require('img/bvnb.svg').default,
    map:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.6986271521655!2d106.65736551521638!3d10.757693862479865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752ef1efebf7d7%3A0x9014ce53b8910a58!2zQuG7h25oIHZp4buHbiBDaOG7oyBS4bqreQ!5e0!3m2!1svi!2s!4v1661140585461!5m2!1svi!2s',
  },
  {
    code: 'BVBN',
    name: 'Bệnh Viện Đa Khoa Tỉnh Bắc Ninh',
    address: 'Đường Nguyễn Quyền, Phường Võ Cường, TP Bắc Ninh',
    img: require('img/bvtn.svg').default,
    map:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.260539702614!2d106.65124351538813!3d10.79134639231138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752ecaab26b129%3A0x6a3e66ad2406aa1c!2zQuG7h25oIHZp4buHbiBUaOG7kW5nIE5o4bqldA!5e0!3m2!1svi!2s!4v1663060604507!5m2!1svi!2s',
  },
];
export const PRINT_HOSPITAL_TYPE = {
  BVNB: {
    img: require('img/bvnb.svg').default,
    code: '37101',
    name: 'Bệnh Viện Đa Khoa Tỉnh Ninh Bình',
    address: '80 Tuệ Tĩnh, Nam Thành, Ninh Bình',
    map:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.6986271521655!2d106.65736551521638!3d10.757693862479865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752ef1efebf7d7%3A0x9014ce53b8910a58!2zQuG7h25oIHZp4buHbiBDaOG7oyBS4bqreQ!5e0!3m2!1svi!2s!4v1661140585461!5m2!1svi!2s',
  },
  BVBN: {
    img: require('img/bvtn.svg').default,
    code: 'BVBN',
    name: 'Bệnh Viện Đa Khoa Tỉnh Bắc Ninh',
    address: 'Đường Nguyễn Quyền, Phường Võ Cường, TP Bắc Ninh',
    map:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.260539702614!2d106.65124351538813!3d10.79134639231138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752ecaab26b129%3A0x6a3e66ad2406aa1c!2zQuG7h25oIHZp4buHbiBUaOG7kW5nIE5o4bqldA!5e0!3m2!1svi!2s!4v1663060604507!5m2!1svi!2s',
  },
};

export const HOSPITAL_TYPE_NEW = [
  {
    code: 'All',
    name: 'Tất cả',
  },
  {
    code: '37101',
    name: 'Bệnh viện Đa Khoa Tỉnh Ninh Bình',
    address: '201B Nguyễn Chí Thanh, Phường 12, Quận 5, TP. Hồ Chí Minh',
  }
];
export const REGISTERED_STATUS = [
  {
    value: 'ALL',
    name: 'Tất cả',
  },
  {
    value: 'PAID',
    name: 'Thành công',
  },
  {
    value: 'PENDING',
    name: 'Chờ thanh toán',
  },
  {
    value: 'CANCELLED',
    name: 'Đã hủy',
  },
];

export const HOSPITAL_IMG = {
  BVNB: {
    img: require('img/icChoray.svg').default,
  }
};
export const PATIENT_TYPE: PatientType[] = [
  {
    id: 'Bảo hiểm y tế',
    name: 'Bảo hiểm y tế',
    img: require('img/bhxh.svg').default,
    type_Department: 0,
  },
  {
    id: 'Khám Dịch vụ có BHYT',
    name: 'Khám Dịch vụ có BHYT',
    img: require('img/DVBHYT.svg').default,
    type_Department: 2,
  },
  {
    id: 'Thu phí',
    name: 'Khám thu phí',
    img: require('img/medical.svg').default,
    type_Department: 1,
  },
];
export const RE_EXAMINATION_TYPE: ReExamination[] = [
  {
    id: 0,
    name: 'Khám mới',
    img: require('img/KhamMoi.svg').default,
  },
  {
    id: 1,
    name: 'Tái khám',
    img: require('img/TaiKham.svg').default,
  },
];
export const PAYMENT = [
  {
    code: 'MOMO',
    name: 'Ví Momo',
    img: require('img/momo_logo.svg').default,
    is_enable: false,
  },
  {
    code: 'ATM',
    name: 'Thẻ ATM nội địa',
    img: require('img/atm_logo.svg').default,
    is_enable: false,
  },
  {
    code: 'VNPAY_QR',
    name: 'VNPAY-QR',
    img: require('img/vnpay_logo.svg').default,
    is_enable: false,
  },
  {
    code: 'INTERNATIONAL_CARD',
    name: 'Thẻ thanh toán quốc tế',
    img: require('img/visa_logo.svg').default,
    is_enable: false,
  },
  {
    code: 'TRANSFER',
    name: 'Chuyển khoản',
    img: require('img/ck_logo.svg').default,
    is_enable: false,
  },
];

export const HOSPITAL_NAME = {
  BVNB: 'Bệnh viện Đa Khoa Tỉnh Ninh Bình',
  BVBN: 'Bệnh viện Đa Khoa Tỉnh Bắc Ninh',
};

export const TREATMENT_NAME = {
  0: 'Ngoại trú',
  1: 'Nội trú',
};

export const PAY_PROVIDER = {
  0: {
    name: 'Thẻ Vimes',
  },
  1: {
    name: 'Ví Momo',
  },
  2: {
    name: 'Thẻ Vietcombank',
  },
  3: {
    name: 'Khác',
  },
};

export const STATUS = {
  0: {
    name: 'Thành công',
    color: 'green',
  },
  1: {
    name: 'Thất bại',
    color: 'red',
  },
};

export const HOSPITAL_INFO = {
  default: {
    name: 'CÔNG TY CỔ PHẦN PHẦN MỀM Y TẾ VIỆT NAM',
    address: '187 Nguyễn Văn Cừ, Long Biên, Hà Nội, Việt Nam',
    website: 'https://vimes.com.vn/',
    email: 'app.vimesjsc@gmail.com',
    hotline: '0902.215.464',
    logoUrl: '/logo-vimes-vertical.png',
  },
  BVNB: {
    name: 'Bệnh viện Đa Khoa Tỉnh Ninh Bình',
    address: '80 Tuệ Tĩnh, Nam Thành, Ninh Bình',
    website: 'http://choray.vn',
    email: 'bvchoray@choray.vn',
    hotline: '(84-028) 3855 4137',
    logoUrl: '/bvcr_logo.svg',
  },
  BVBN: {
    name: 'Bệnh viện Đa Khoa Tỉnh Bắc Ninh',
    address: 'Đường Nguyễn Quyền, Phường Võ Cường, TP Bắc Ninh',
    website: 'http://bvtn.org.vn/',
    email: 'thongnhathospital@bvtn.org.vn',
    hotline:
      '028 3869 0277 | Kế Hoạch Tổng Hợp: 028 38642 142 | Fax: 028 3865 6715',
    logoUrl: '/bvtn_logo.png',
  },
};
export const HOSPITAL_INFO_NEW = {
  default: {
    name: 'CÔNG TY CỔ PHẦN PHẦN MỀM Y TẾ VIỆT NAM',
    address: '187 Nguyễn Văn Cừ, Long Biên, Hà Nội, Việt Nam',
    website: 'https://vimes.com.vn/',
    email: 'app.vimesjsc@gmail.com',
    hotline: '0902.215.464',
    logoUrl: '/logo-vimes-vertical.png',
  },
  BVNB: {
    name: 'Bệnh viện Đa Khoa Tỉnh Ninh Bình',
    address: '80 Tuệ Tĩnh, Nam Thành, Ninh Bình',
    website: 'http://choray.vn',
    email: 'bvchoray@choray.vn',
    hotline: '(84-028) 3855 4137',
    logoUrl: '/bvcr_logo.svg',
  },
  BVBN: {
    name: 'Bệnh viện Đa Khoa Tỉnh Bắc Ninh',
    address: 'Đường Nguyễn Quyền, Phường Võ Cường, TP Bắc Ninh',
    website: 'http://bvtn.org.vn/',
    email: 'thongnhathospital@bvtn.org.vn',
    hotline:
      '028 3869 0277 | Kế Hoạch Tổng Hợp: 028 38642 142 | Fax: 028 3865 6715',
    logoUrl: '/bvtn_logo.png',
  },
};

export const appLink = {
  google: 'https://play.google.com/store/apps/details?id=com.vimesjsc.app',
  apple: 'https://apps.apple.com/vn/app/vimes/id1560093824',
  download: 'https://download.app.vimes.com',
};
export const API_MAIN = process.env.REACT_APP_API_BASE;
export const API_AUTH = process.env.REACT_APP_API_AUTH;
export type StatusType = 'PENDING' | 'PAID' | 'CANCELLED';

export interface BillStatusType {
  status: StatusType;
  name: string;
  color: string;
}

export enum OrderTransactionStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum OrderStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  REFUND = 'REFUND',
}

export enum BillStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
}

export interface BillStatusMetaType {
  status: BillStatus;
  name: string;
  color: string;
}

export const BillStatusList: BillStatusMetaType[] = [
  {
    status: BillStatus.PAID,
    name: 'Đăng ký thành công',
    color: '#4AE001',
  },
  {
    status: BillStatus.PENDING,
    name: 'Chờ thanh toán phí đăng ký',
    color: '#1890FF',
  },
  {
    status: BillStatus.CANCELLED,
    name: 'Đã huỷ',
    color: '#EB262E',
  },
];
