/* eslint-disable react-hooks/exhaustive-deps */
import 'animation/AnimatedSwitch.css';
import { bounceTransition, mapStyles } from 'animation/AnimationSwitch';
import { Drawer } from 'app/components/Drawer';
import { Footer } from 'app/components/Footer';
import { Header } from 'app/components/Header';
import ScrollToTop from 'app/components/ScrollToTop';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { AppRouting, RoutePermissions } from 'router';
import * as authSelectors from 'store/auth/selectors';
import * as authSlice from 'store/auth/slice';
import {
  useInjectReducer,
  useInjectSaga,
} from 'store/core/@reduxjs/redux-injectors';
import * as layoutSelectors from 'store/layout/selectors';
import * as layoutSlice from 'store/layout/slice';
import { gtagSendPageView } from 'utils/analysis';
import { AppPopupProvider } from './containers/AppPopup';
import * as sharedSlice from 'store/shared/slice';
import { SharedSaga } from 'store/shared/saga';
import * as featureSlice from 'store/Feature/slice';
import { FeatureSaga } from 'store/Feature/saga';

export function withFooter(Component: React.ComponentType<any>) {
  return () => (
    <>
      <div
        style={{
          minHeight: 'calc(100vh - 350px)',
        }}
      >
        <Component />
      </div>
      <Footer />
    </>
  );
}

export function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  useInjectReducer({ key: layoutSlice.sliceKey, reducer: layoutSlice.reducer });
  useInjectReducer({ key: authSlice.sliceKey, reducer: authSlice.reducer });
  useInjectReducer({ key: sharedSlice.sliceKey, reducer: sharedSlice.reducer });
  useInjectSaga({ key: sharedSlice.sliceKey, saga: SharedSaga });
  useInjectReducer({
    key: featureSlice.sliceKey,
    reducer: featureSlice.reducer,
  });
  useInjectSaga({
    key: featureSlice.sliceKey,
    saga: FeatureSaga,
  });
  // Drawer
  const drawerOpen = useSelector(layoutSelectors.selectDrawerOpen);

  // Permission
  const permissions = useSelector(authSelectors.selectPermissions);

  // Analysis website
  useEffect(() => {
    gtagSendPageView(`${history.location.pathname}${history.location.search}`);
    history.listen(e => {
      gtagSendPageView(`${e.pathname}${e.search}`);
    });
  }, []);
  useEffect(() => {
    dispatch(featureSlice.actions.getFeature({ hospitalCode: '' }));
  }, []);
  const renderAppRouting = () => {
    return AppRouting.map(({ path, title, Component, permission }, idx) => {
      if (
        !permission ||
        permission === RoutePermissions.CLIENT ||
        permissions.includes(permission)
      )
        return (
          <Route
            exact
            path={path}
            key={idx}
            render={props => (
              <>
                <Helmet>
                  <title>Vimes - {title}</title>
                  <meta name="description" content={title} />
                </Helmet>
                <Component />
              </>
            )}
          />
        );
      return (
        <Route exact path={path} key={idx}>
          <Redirect to={'/dang-nhap?a=' + path.replace('/', '')} />
        </Route>
      );
    });
  };

  return (
    <>
      <div className="style_wrapper_template__kPnXT">
        <Header />
        <ScrollToTop />
        <Switch>
          <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"
            runOnMount={true}
            // didLeave={a => {
            //   fixFooterPos();
            // }}
          >
            {renderAppRouting()}
            <Route>
              <Redirect to="/" />
            </Route>
          </AnimatedSwitch>
        </Switch>
        <Drawer
          visible={drawerOpen}
          onClose={() => dispatch(layoutSlice.actions.closeDrawer())}
        />
      </div>

      {/* <HospitalSelector /> */}

      {isMobile && <AppPopupProvider />}
    </>
  );
}
