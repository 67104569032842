/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { configResponse, configResponseNew } from 'utils/request';
import { actions } from './slice';
import { API_MAIN } from 'constants/app.constants';
import * as _ from 'lodash';
import * as sharedSelectors from 'store/shared/selectors';
import * as authSelectors from '../auth/selectors';
import moment from 'moment';
import { ProfileHttp } from './../profile/services.http';
import { ShareHttp } from './services.http';

const shareHttp = new ShareHttp(API_MAIN);
const profileHttp = new ProfileHttp(API_MAIN);

export function* specialistAll(api: ShareHttp, action) {
  const hospitalCode = yield select(sharedSelectors.selectHospitalCode);
  const specialists = yield select(sharedSelectors.selectSpecialists);
  if (specialists?.length > 0) {
    yield put(actions.fetchSpecialistsSuccess(specialists));
    return;
  }
  const response = yield call(api.specialistAll, {
    hospital_Code: hospitalCode || action.payload.hospital_Code,
    type_Department: action.payload.type_Department,
    type: 'online',
  });
  try {
    const data = yield configResponse(response);
    yield put(actions.fetchSpecialistsSuccess(data));
  } catch (error) {
    yield put(actions.fetchSpecialistsFailure(_.get(error, 'message')));
  }
}

export function* specialistDepartment(api: ShareHttp, action) {
  const hospitalCode = yield select(sharedSelectors.selectHospitalCode);
  if (action.payload?.specialistCode) {
    const response = yield call(
      api.specialistDepartment,
      action.payload?.specialistCode,
      {
        hospital_Code: hospitalCode,
        type_Department: action.payload?.type_Department,
        type: 'online',
      },
    );
    try {
      const data = yield configResponse(response);
      yield put(actions.fetchDepartmentsSuccess(data));
    } catch (error) {
      yield put(actions.fetchDepartmentsFailure(_.get(error, 'message')));
    }
  } else {
    yield put(actions.fetchDepartmentsFailure('departmentCode not found'));
  }
}

export function* timeSlot(api: ShareHttp, action) {
  const hospitalCode = yield select(sharedSelectors.selectHospitalCode);
  if (action.payload?.departmentCode || action.payload?.registeredDate) {
    const response = yield call(api.timeSlot, {
      hospital_Code: hospitalCode,
      type: 'online',
      department_Code: action.payload?.departmentCode,
      registered_Date: action.payload?.registeredDate,
    });
    try {
      const data = yield configResponse(response);
      yield put(actions.fetchTimeSlotSuccess(data));
    } catch (error) {
      yield put(actions.fetchTimeSlotFailure(_.get(error, 'message')));
    }
  } else {
    yield put(
      actions.fetchTimeSlotFailure(
        'specialistCode or registeredDate not found',
      ),
    );
  }
}

export function* getPatientCode(api: ShareHttp, action) {
  const data = {
    search_Value: action.payload.patientCode,
    hospital_Code: action.payload.hospitalCode,
  };
  const response = yield call(api.getPatientCode, data);
  try {
    const data = yield configResponse(response);
    yield put(actions.getPatientCodeSuccess(data));
  } catch (error) {
    yield put(actions.getPatientCodeFailed(_.get(error, 'message')));
  }
}

export function* getPatientCodes(api: ShareHttp, action) {
  const response = yield call(
    api.getPatientCodes,
    action.payload.patientCode,
    action.payload.hospitalCode,
    action.payload.cardNumber,
  );
  try {
    const data = yield configResponse(response);
    yield put(actions.getPatientCodesSuccess(data));
  } catch (error) {
    yield put(actions.getPatientCodesFailed(_.get(error, 'message')));
  }
}

export function* postPatientCode(api: ProfileHttp, action) {
  const userId = yield select(authSelectors.selectUserId);
  const response = yield call(api.userPatientMap, {
    user_id: userId,
    patient_code: action.payload.patientCode,
    hospital_code: action.payload.hospitalCode,
  });
  try {
    const data = yield configResponse(response);
    yield put(actions.postPatientMapSuccess());
  } catch (error) {
    yield put(actions.postPatientMapFailed(_.get(error, 'message')));
  }
}
export function* postUnMapPatientCode(api: ProfileHttp, action) {
  const userId = yield select(authSelectors.selectUserId);
  const response = yield call(api.userPatientUnMap, {
    user_id: userId,
    patient_code: action.payload.patientCode,
    hospital_code: action.payload.hospitalCode,
  });
  try {
    const data = yield configResponse(response);
    yield put(actions.postPatientUnMapSuccess());
  } catch (error) {
    yield put(actions.postPatientUnMapFailed(_.get(error, 'message')));
  }
}
export function* getAllUserPatient(api: ShareHttp, action) {
  const userId = yield select(authSelectors.selectUserId);
  const response = yield call(api.getAllUserPatient, userId);
  try {
    const data = yield configResponse(response);
    yield put(actions.getAllUserPatientCodeSuccess(data));
  } catch (error) {
    yield put(actions.getAllUserPatientCodeFailed(_.get(error, 'message')));
  }
}
export function* getRegisterInfoExamination(api: ShareHttp) {
  const userName: any = yield select(authSelectors.selectUserId);
  const response = yield call(
    api.getRegisterInfoExaminationByUserId,
    userName,
    // {
    //   hospital_Code: 'BVNB',
    //   type: 'online',
    // },
  );
  try {
    const data = yield configResponse(response);
    data.forEach(e => {
      if (!e.is_active) {
        e.status = 2;
      } else {
        const d = moment(
          moment(e?.apply_date).format('YYYY-MM-DD') + 'T' + e?.start_expected,
        );
        e.status = d.isAfter(moment()) ? 0 : 1;
      }
    });
    yield put(actions.getRegisterInfoExaminationSuccess(data));
  } catch (error) {
    yield put(actions.getRegisterInfoExaminationFail(_.get(error, 'message')));
  }
}
export function* cancelRegisterInfoExamination(api: ShareHttp, action) {
  const hospitalCode = yield select(sharedSelectors.selectHospitalCode);
  const response = yield call(api.postCancelExamination, {
    booking_Code: action.payload.bookingCode,
    hospital_Code: action.payload.hospitalCode || hospitalCode,
    type: 'online',
  });
  try {
    const data = yield configResponse(response);
    yield put(actions.postCancelExaminationSuccess(data));
  } catch (error) {
    yield put(actions.postCancelExaminationFail(_.get(error, 'message')));
  }
}
export function* cancelRegistersInfoExamination(api: ShareHttp, action) {
  const response = yield call(api.postCancelExamination, {
    booking_Code: action.payload.bookingCode,
    hospital_Code: action.payload.hospitalCode,
    type: 'online',
  });
  try {
    const data = yield configResponse(response);
    yield put(actions.postCancelExaminationSuccess(data));
  } catch (error) {
    yield put(actions.postCancelExaminationFail(_.get(error, 'message')));
  }
}
export function* postInfoExaminationWithBookingCode(api: ShareHttp, action) {
  const response = yield call(
    api.postBookingCode,
    action.payload.booking_Code,
    {
      hospital_Code: action.payload.hospitalCode,
      type: 'online',
    },
  );
  try {
    const data = yield configResponseNew(response);
    yield put(actions.postInfoExaminationBookingCodeSuccess(data));
  } catch (error) {
    yield put(
      actions.postInfoExaminationBookingCodeFailed(_.get(error, 'message')),
    );
  }
}
export function* getListReExamination(api: ShareHttp, action) {
  const response = yield call(api.getListReExamination, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.getListReExaminationSuccess(data));
  } catch (error) {
    yield put(actions.getListReExaminationFailed(_.get(error, 'message')));
  }
}
export function* SharedSaga() {
  yield all([
    yield takeLatest(actions.fetchSpecialists.type, specialistAll, shareHttp),
    yield takeLatest(
      actions.fetchDepartments.type,
      specialistDepartment,
      shareHttp,
    ),
    yield takeLatest(actions.fetchTimeSlot.type, timeSlot, shareHttp),
    yield takeLatest(actions.getPatientCode.type, getPatientCode, shareHttp),
    yield takeLatest(actions.getPatientCodes.type, getPatientCodes, shareHttp),
    yield takeLatest(actions.postPatientMap.type, postPatientCode, profileHttp),
    yield takeLatest(
      actions.postPatientUnMap.type,
      postUnMapPatientCode,
      profileHttp,
    ),
    yield takeLatest(
      actions.getAllUserPatientCode.type,
      getAllUserPatient,
      shareHttp,
    ),
    yield takeLatest(
      actions.getRegisterInfoExamination.type,
      getRegisterInfoExamination,
      shareHttp,
    ),
    yield takeLatest(
      actions.postCancelExamination.type,
      cancelRegisterInfoExamination,
      shareHttp,
    ),
    yield takeLatest(
      actions.postCancelExaminations.type,
      cancelRegistersInfoExamination,
      shareHttp,
    ),
    yield takeLatest(
      actions.postInfoExaminationBookingCode.type,
      postInfoExaminationWithBookingCode,
      shareHttp,
    ),
    yield takeLatest(
      actions.getListReExamination.type,
      getListReExamination,
      shareHttp,
    ),
  ]);
}
