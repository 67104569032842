/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Drawer as Sidebar } from 'antd';
import * as appConstants from 'constants/app.constants';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as authSelectors from 'store/auth/selectors';
import * as AuthSlice from 'store/auth/slice';
import {
  useInjectReducer,
  useInjectSaga,
} from 'store/core/@reduxjs/redux-injectors';
import { SharedSaga } from 'store/shared/saga';
import * as SharedSlice from 'store/shared/slice';
import { clearLocalStorage } from 'utils';
import CloseIcon from '@material-ui/icons/Close';
import './styles.scss';
import SeachInfoPatient from '../SeachInfoPatient';
import * as multiProfileSlice from 'store/multi-profile/slice';
import { MultiProfileSaga } from 'store/multi-profile/saga';

export function Drawer({ visible, onClose }) {
  useInjectReducer({ key: SharedSlice.sliceKey, reducer: SharedSlice.reducer });
  useInjectSaga({ key: SharedSlice.sliceKey, saga: SharedSaga });
  useInjectReducer({
    key: multiProfileSlice.sliceKey,
    reducer: multiProfileSlice.reducer,
  });
  useInjectSaga({ key: multiProfileSlice.sliceKey, saga: MultiProfileSaga });
  const history = useHistory();
  const dispatch = useDispatch();
  // const userName = localStorage.getItem('__userName');
  const userName = useSelector(authSelectors.selectUserName);
  const token = authSelectors.selectToken;
  const isLogin = userName && token !== null;

  const changeRoute = () => {
    onClose();
  };
  return (
    <Sidebar placement="left" visible={visible} width="266px">
      <div className="drawer-wrapper container">
        <div className="style-side-nav wide right-aligned style_bg_menu_mobile right-side-slide-enter-done">
          <div
            className="scrollbar-container ps ps--active-y"
            style={{
              overflow: window.innerWidth < 500 ? 'auto !important' : 'hidden',
            }}
          >
            <div
              className="d-flex"
              style={{ flexDirection: 'column', height: '95vh' }}
            >
              <ul className="list-unstyled">
                <li>
                  <div className="drawer__logo__wrapper">
                    <Link to={'/'} onClick={changeRoute}>
                      <img
                        src={require('img/logoVimes.svg').default}
                        className="img-fluid flex-center d-block"
                      />
                    </Link>
                  </div>
                </li>
                <li className="style_close">
                  <button type="button" onClick={onClose}>
                    <CloseIcon />
                  </button>
                </li>
                <li className="style_SeachValue">
                  <SeachInfoPatient onClick={changeRoute} />
                </li>

                <div className="border-bottom">
                  <li>
                    <Link to={'/'} onClick={changeRoute}>
                      Trang chủ
                    </Link>
                  </li>
                  <li>
                    <Link to={'/gioi-thieu'} onClick={changeRoute}>
                      Giới thiệu
                    </Link>
                  </li>
                  <li>
                    <Link to={'/quy-trinh'} onClick={changeRoute}>
                      Quy trình
                    </Link>
                  </li>
                </div>
                <li className="style_li_dangnhap">
                  {isLogin ? (
                    <button
                      className=" style_button style_creat"
                      onClick={() => {
                        clearLocalStorage();
                        dispatch(AuthSlice.actions.logoutSuccess(null));
                        dispatch(SharedSlice.actions.clear());
                        dispatch(
                          SharedSlice.actions.resetUserInforPatientCodeHopital(),
                        );
                        history.push('/');
                        dispatch(multiProfileSlice.actions.reset());
                        onClose();
                      }}
                    >
                      ĐĂNG XUẤT
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        history.push('/dang-nhap');
                        onClose();
                      }}
                      data-test="Link"
                      type="button"
                      className="style_button style_creat"
                    >
                      <div>Đăng nhập/ Đăng ký</div>
                    </button>
                  )}
                </li>
              </ul>
              <ul className="style_content_bottom">
                <div>
                  <li className="style_li_download">
                    <div className="style_download">
                      <p className="style_txtDown">
                        Tải ứng dụng{' '}
                        <span style={{ color: '#D61B22', fontWeight: 'bold' }}>
                          {' '}
                          Vimes
                        </span>{' '}
                        tại
                      </p>
                      <ul className="style_list_group">
                        <div className="mr-2">
                          <div className="lazyload-wrapper style_image">
                            <a
                              href={appConstants.appLink.apple}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  require('./images/appstore.cdb12f48.svg')
                                    .default
                                }
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                        <div className="ml-2">
                          <div className="lazyload-wrapper style_image">
                            <a
                              href={appConstants.appLink.google}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  require('./images/googleplay.142f41c8.svg')
                                    .default
                                }
                              />
                            </a>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </li>
                  <div>
                    <a
                      style={{ height: 'fit-content', fontWeight: 'normal' }}
                      onClick={changeRoute}
                    >
                      Hỗ trợ đặt khám: 1900-2115
                    </a>
                  </div>
                  <div>
                    <a
                      style={{ height: 'fit-content', fontWeight: 'normal' }}
                      onClick={changeRoute}
                    >
                      Email: app.vimesjsc@gmail.com
                    </a>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
